<template>
    <main class="models-page h-100 d-flex h-100 flex-column">

        <section class="border-bottom bg-lighter md-up:sticky-top" style="top: 70px;">
            <div class="container py-3">
                <nav class="nav sm-down:flex-column">

                    <router-link :to="{name: 'Round_Post', params: {arr_id}}" class="btn btn-sm btn-light text-left d-block sm-down:w-fill">
                        <i class="i-package float-left"></i>
                        <span>{{ $t('new_round') }}</span>
                    </router-link>

                    <router-link :to="{name: 'Rounds', params: {arr_id}}" class="btn btn-sm btn-light text-left d-block sm-down:w-fill">
                        <i class="i-grid float-left"></i>
                        <span>{{ $t('open_list') }}</span>
                    </router-link>
                </nav>
            </div>
        </section>

        <section class="p-relative py-10" style="flex: 1; min-height: calc(100vh - 195px);">
            <gmap-map class="fill-parent"
                      :zoom="17"
                      :center="center"
                      style="position: absolute;top: 0;left: 0;"
                      :options="{
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false
                          }"
                      map-type-id="terrain">

                <gmap-marker v-for="(round, i) in rounds" :key="i"
                             :clickable="true"
                             :icon="icon(round)"
                             @click="$router.push('/round/'+round.idt_round)"
                             :position="{lat: round.lat, lng: round.lng}"/>
            </gmap-map>
        </section>
    </main>
</template>

<script>
    export default {
        name: 'RoundsPage',
        props: ['arr_id'],
        data() {
            return {
                rounds: [],
                loaded: false,
                center: {
                    lat: 59.9598743,
                    lng: 30.2965115
                }
            }
        },
        mounted() {
            this.$navbar.name = 'Rounds';

            this.loadPage();
        },
        methods: {

            icon(round) {
                return {
                    scaledSize: new window['google'].maps.Size(39.38, 70),
                    origin: new window['google'].maps.Point(0, 0),
                    anchor: new window['google'].maps.Point(0, 0),
                    url: round['url_preview']
                }
            },

            loadPage() {
                this.loaded = false;

                let params = {
                    idt_arp: this.arr_id,
                    limit: 500,
                    offset: 0
                };

                this.$api.v2.get('/user/roundList', {params})
                    .then(response => {
                        this.length = response.data.count;
                        this.loaded = true;

                        this.$navbar.name = `${ this.$t('rounds') } (${ this.length })`;

                        response.data.rounds.map(item => this.rounds.push(item));
                    })
            }
        }
    }
</script>
